@tailwind base;
@tailwind components;
@tailwind utilities;

/* Modal styles */
.Modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: white;
    border: none;
    border-radius: 0;
  }
  
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000; /* Ensure the overlay is on top of other elements */
  }
  